import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import { MainStore } from "./context/MainContext";
import ScrollToTop from "./settings/ScrollToTop";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
// var userAgent = navigator.userAgent;
// console.log("userAgent :", userAgent);
// console.log("isbot :", isbot(userAgent));

// userAgent.includes("facebookexternalhit") ||
// userAgent.includes("FacebookBot") ||
// userAgent.includes("Facebot") ||
// userAgent.includes("Twitterbot")

// if (isbot(userAgent)) {
//   console.log("redirecting....");
//   window.location.redirect(
//     process.env.REACT_APP_SOCIAL_SHARE_URL + window.location.pathname
//   );
//   // window.location.replace(
//   //   process.env.REACT_APP_SOCIAL_SHARE_URL + window.location.pathname
//   // );
// } else {
root.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
    <MainStore>
      <ScrollToTop />
      <App />
    </MainStore>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);
// }

// window.location.replace(
//   process.env.REACT_APP_SOCIAL_SHARE_URL + window.location.pathname + "/1"
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
