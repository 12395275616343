import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Menu as AntdMenu, Drawer } from "antd";
import "antd/dist/antd.min.css";
// import "./style.css";

import { menuData as menus } from "../../mock-data/Data";
import Container from "../container/Container";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const onHandleMenu = () => {
    setShowMenu(!showMenu);

    if (!showMenu) {
      onShowDrawer();
    }
  };

  const onShowDrawer = () => {
    setDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setShowMenu(false);
  };

  const onClickMenu = (event) => {
    navigate(event.key);
    onCloseDrawer();
  };

  const onClickEng = () => {
    window.open("http://english.bgd.mn/");
  };

  return (
    <header className="mx-auto w-full z-20 bg-white sticky inset-x-0 top-0 left-0">
      <div className="flex items-center justify-center">
        <Container>
          <div className="flex items-center justify-start md:justify-between lg:justify-between h-[50px] md:h-9 lg:h-16">
            {/* Hamburger menu icon */}
            <div className="flex md:hidden cursor-pointer px-6 py-[22px]">
              <i
                className="fa-solid fa-bars text-lg text-bgd-main_blue_color"
                onClick={onHandleMenu}
              />
            </div>

            {/* mobile uyd haragdah logo */}
            <Link to="/" className="h-[45px] cursor-pointer block md:hidden">
              <img
                className="h-full"
                src="/assets/images/header_logo300px.png"
                alt="logo"
                loading="lazy"
              />
            </Link>

            {/* <span className="text-bgd-main_blue_color text-[13px] font-bold md:flex hidden">
              Тавтай морил
            </span> */}

            {/* Logo */}
            <Link to="/" className="hidden md:block">
              <img
                className="lg:h-[55px] h-[40px] cursor-pointer"
                src="/assets/images/header_logo300px.png"
                alt="logo"
              />
            </Link>

            {/* Buttons */}
            <div className="flex flex-row gap-2.5 md:flex hidden">
              <Link
                to={"/search"}
                className="text-white font-light text-sm text-center px-2.5 py-[5px] w-[45px] bg-bgd-footer_color rounded hover:bg-bgd-footer_color/70 hover:text-white"
              >
                <i className="fa-regular fa-magnifying-glass" />
              </Link>

              <button
                className="text-white font-medium text-sm px-2.5 py-[5px] w-[45px] bg-bgd-footer_color rounded hover:bg-bgd-footer_color/70 hover:text-white"
                onClick={() => onClickEng()}
              >
                Eng
              </button>
            </div>
          </div>

          {/* Mobile Responsive */}
          {showMenu && (
            <Drawer
              bodyStyle={{ padding: "0px" }}
              width={`80%`}
              // title="Basic Drawer"
              placement="left"
              onClose={onCloseDrawer}
              open={drawerOpen}
            >
              <AntdMenu
                className="menu_mobile"
                mode="inline"
                onClick={onClickMenu}
                items={menus}
              />
            </Drawer>
          )}
        </Container>
      </div>
    </header>
  );
}
