import React from "react";

export default function Banner() {
  return (
    <div className="hidden lg:block fixed bottom-[40%] right-1 z-20 space-y-1">
      {/* <img
        src="https://ulaanbaatar.mn/files/mn/image/kuqb6edo7l/63772df26aae8c6e0b2d10ea.jpg"
        width={"125px"}
        alt="banner"
      /> */}
      {/* <div className="bg-red-500 w-24 right-[calc(100vh-36px)]"></div> */}
    </div>
  );
}
