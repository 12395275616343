import React, { useContext } from "react";
import { Link } from "react-router-dom";

import MainContext from "../../context/MainContext";
import Container from "../container/Container";

export default function PageFooter() {
  const mainContext = useContext(MainContext);

  return (
    <footer className="bg-white pt-[25px] ">
      <Container>
        {/* flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-col */}
        <div className="px-0 py-12 lg:py-24 border-t border-solid border-slate-100">
          <div className="flex flex-wrap justify-between -mb-10 md:mt-0 mt-10 md:text-left text-center">
            {/* Logo */}
            <div className="hidden lg:block lg:w-1/4 md:w-1/2 w-full pr-4">
              <img src="/assets/images/header_logo300px.png" alt="logo" />
            </div>

            {/* Хаяг */}
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              {/* border-l-[3px] border-solid pl-2.5 border-bgd-main_blue_color */}
              <h2 className="font-bold text-black text-xs mb-3 uppercase">
                Хаяг
              </h2>
              <nav className="list-none mb-10">
                <p className="mt-0 text-sm text-black">
                  Монгол Улс, Улаанбаатар хот, Баянгол дүүрэг, 4-р хороо,
                  Замчдын гудамж-3, Баянгол дүүргийн Засаг Даргын Тамгын газар.
                </p>
              </nav>
            </div>

            {/* Холбоо барих */}
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              {/* border-l-[3px] border-solid pl-2.5 border-bgd-main_blue_color */}
              <h2 className="font-bold text-black text-xs mb-3 uppercase">
                Холбоо барих
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <div className="text-black text-xm font-normal">
                    <i className="fa-regular fa-phone-flip text-xs" />
                    {" +(976)-70120320"}
                    <br />
                    <small className="text-black/60 font-normal">
                      /НЗДТГ-ын Угтах үйлчилгээ/
                    </small>
                  </div>
                </li>
                <li>
                  <div className="text-black text-xm font-normal">
                    <i className="fa-regular fa-envelope text-xs" />
                    {" info@bgd.mn"}
                    <br />
                    <small className="text-black/60 font-normal">
                      /Цахим хаяг/
                    </small>
                  </div>
                </li>
              </nav>
            </div>

            {/* Хандалтын тоо */}
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              {/* border-l-[3px] border-solid pl-2.5 border-bgd-main_blue_color */}
              <h2 className="font-bold text-black text-xs mb-3 uppercase">
                Хандалтын тоо
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <label className="text-black hover:text-gray-800" to="">
                    {
                      <>
                        Өнөөдөр:{" "}
                        <b>
                          {parseInt(
                            mainContext.accessState.day_value
                          ).toLocaleString()}
                        </b>
                      </>
                    }
                  </label>
                </li>
                <li>
                  <label className="text-black hover:text-gray-800" to="">
                    <>
                      Энэ 7 хоног:{" "}
                      <b>
                        {parseInt(
                          mainContext.accessState.week_value
                        ).toLocaleString()}
                      </b>
                    </>
                  </label>
                </li>
                <li>
                  <label className="text-black hover:text-gray-800" to="">
                    <>
                      Энэ сар:{" "}
                      <b>
                        {parseInt(
                          mainContext.accessState.month_value
                        ).toLocaleString()}
                      </b>
                    </>
                  </label>
                </li>
                <li>
                  <label className="text-black hover:text-gray-800" to="">
                    <>
                      Нийт:{" "}
                      <b>
                        {parseInt(
                          mainContext.accessState.all_value
                        ).toLocaleString()}
                      </b>
                    </>
                  </label>
                </li>
              </nav>
            </div>
          </div>
        </div>
      </Container>

      <div className="bg-bgd-footer_color border border-bgd-footer_color">
        <Container>
          <div className="py-4 px-5 flex flex-wrap flex-col sm:flex-row items-center">
            <p className="text-white text-xs font-normal text-center sm:text-left">
              © 2022 ЗОХИОГЧИЙН ЭРХ ХУУЛИАР ХАМГААЛАГДСАН. САЙТЫН БҮХ ЭРХИЙГ
              БАЯНГОЛ ДҮҮРЭГ ЭЗЭМШИНЭ.
              {/* <a
                href="https://twitter.com/knyttneve"
                rel="noopener noreferrer"
                className="text-gray-600 ml-1"
                target="_blank"
              >
                @knyttneve
              </a> */}
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
              {/* FB ICON */}
              <a
                className="text-white"
                href="https://www.facebook.com/shuurkhaishtab"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>

              {/* TWITTER ICON */}
              <a
                className="ml-3 text-white"
                href="https://twitter.com/Bayangol_UB"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>

              {/* INSTA ICON */}
              {/* <Link className="ml-3 text-white" to="">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </Link> */}

              {/* LINKEDIN ICON */}
              {/* <Link className="ml-3 text-white" to="">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </Link> */}
            </span>
          </div>
        </Container>
      </div>
    </footer>
  );
}
