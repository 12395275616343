import React, { useState } from "react";

const MainContext = React.createContext();
const initialState = {
  status: "",
  day_value: 0,
  week_value: 0,
  month_value: 0,
  all_value: 0,
};

export const MainStore = (props) => {
  const [accessState, setAccessState] = useState(initialState);

  const changeAccessData = (pObj) => {
    setAccessState(pObj);
  };

  return (
    <MainContext.Provider value={{ accessState, changeAccessData }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContext;
