import React from "react";
import { useNavigate } from "react-router-dom";

import { Menu as AntdMenu } from "antd";

import "./style.css";
import { menuData as menus } from "../../mock-data/Data";

export default function Menu() {
  const navigate = useNavigate();

  const onClick = (event) => {
    if (event.key.includes("http")) {
      window.open(event.key, "_blank");
    } else {
      navigate(event.key);
    }
  };

  // const onClickEng = () => {
  //   window.open("http://english.bgd.mn/");
  // };

  return (
    // bg-bgd-main_blue_color hidden lg:block sticky top-0 z-50
    // <div className="sticky top-0 z-40 hidden lg:block w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 dark:border-slate-50/[0.06] bg-bgd-main_blue_color supports-backdrop-blur:bg-white/95">
    //   <div className="flex justify-center">
    //     <AntdMenu
    //       className="menu-test uppercase"
    //       mode="horizontal"
    //       onClick={onClick}
    //       items={menus}
    //       disabledOverflow
    //       style={{
    //         backgroundColor: "transparent",
    //         borderBottom: "0px",
    //         color: "white",
    //         fontSize: "12px",
    //         fontWeight: "bold",
    //       }}
    //     />
    //   </div>
    // </div>

    <div className="sticky top-[64px] z-50 hidden lg:block w-full bg-bgd-main_text_color shadow-md text-bgd-main_blue_color ">
      <div className="flex justify-center items-center">
        {/* Logo */}
        {/* <Link to="/">
          <img
            className="lg:h-[65px] h-[60px] cursor-pointer"
            src="/assets/images/header_logo300px.png"
            alt="logo"
          />
        </Link> */}

        <AntdMenu
          className="menu-test uppercase"
          mode="horizontal"
          onClick={onClick}
          items={menus}
          disabledOverflow
          style={{
            backgroundColor: "transparent",
            borderBottom: "0px",
            color: "white",
            fontSize: "12px",
            fontWeight: "normal",
          }}
        />
      </div>
    </div>
  );
}
