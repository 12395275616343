export const HOME = "/";
export const SEARCH = "/search";

// Баянгол дүүрэг цэс
export const ABOUT_US = "/pages/about-us";
export const SYMBOL = "/pages/symbol";
export const ANTHEM = "/pages/anthem";
export const GREETINGS = "/pages/greetings";
export const PROGRAM = "/pages/program";
export const STRATEGY = "/pages/strategy";
export const ABOUT_US_DEPARTMENT = "/pages/about-us-department";
export const VISION = "/pages/vision";
export const EXECUTIVES = "/pages/executives";

// Мэдээ цэс
export const NEWSFEED = "/pages/newsfeed";
export const NEWSFEED_DETAIL = "/news/:id";
export const VIDEO_NEWS = "/pages/videonews";
export const VIDEO_NEWS_DETAIL = "/videonews/:id";
export const PHOTO_NEWS = "/pages/photonews";
export const PHOTO_NEWS_DETAIL = "/photonews/:id";
export const QUICK_ADVICE = "/pages/shuurhai-list";
export const QUICK_ADVICE_DETAIL = "/shuurhai/:id";
export const PROCUREMENT_LIST = "/pages/procurement-list";
export const ORDER_LIST = "/pages/order-list";

// Хороо цэс
export const COMMITTEE_LIST = "/pages/horoo-list";
export const COMMITTEE_DETAIL = "/horoo/:id";

// Төрийн үйлчилгээ цэс
export const SERVICE_LIST = "/pages/service-list";
export const SERVICE_DETAIL = "/service/:id";

// Ил тод байдал цэс
export const TRANS_FIN = "/pages/trans_fin";
export const TRANS_WORK = "/pages/trans_work";
export const TRANS_HR = "/pages/trans_hr";
export const TRANS_PROC = "/pages/trans_proc";
export const TRANS_COMP = "/pages/trans_comp";
export const TRANS_ARCH = "/pages/trans_arch";
export const TRANS_LAW = "/pages/trans_law";
export const TRANS_TIME = "/pages/trans_time";
export const TRANS_ELEC = "/pages/trans_elec";
export const TRANS_RATE = "/pages/trans_rate";

// Хэлтэс цэс
export const DEPARTMENT_MAIN = "/pages/mainDepartments";
export const DEPARTMENT_RELATED = "/pages/relatedDepartments";
export const DEPARTMENT_DETAIL = "/department/:id";

// Шилэн данс цэс
export const TRANS_AJITAN = "pages/trans_ajiltan";
