import React from "react";

import Header from "../header/Header";
import PageFooter from "../footer/Footer";
import Menu from "../menu/Menu";
import SocialButtons from "../banner/SocialButtons";
import Banner from "../banner/Banner";
import GoUpButton from "../../settings/GoUpButton";

export default function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Menu />
      <SocialButtons />
      <div className="flex-grow pt-0 px-[0px] lg:px-[0px] lg:space-y-3">
        <main>{children}</main>
      </div>
      <Banner />
      <PageFooter />
      <GoUpButton />
    </div>
  );
}
