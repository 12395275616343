import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./components/layout/Layout";

import {
  HOME,
  ABOUT_US,
  SYMBOL,
  ANTHEM,
  NEWSFEED,
  VIDEO_NEWS,
  PHOTO_NEWS,
  NEWSFEED_DETAIL,
  VIDEO_NEWS_DETAIL,
  PHOTO_NEWS_DETAIL,
  QUICK_ADVICE,
  QUICK_ADVICE_DETAIL,
  PROCUREMENT_LIST,
  ORDER_LIST,
  GREETINGS,
  PROGRAM,
  STRATEGY,
  ABOUT_US_DEPARTMENT,
  VISION,
  EXECUTIVES,
  COMMITTEE_LIST,
  COMMITTEE_DETAIL,
  SERVICE_LIST,
  SERVICE_DETAIL,
  TRANS_FIN,
  TRANS_WORK,
  TRANS_HR,
  TRANS_PROC,
  TRANS_COMP,
  TRANS_ARCH,
  TRANS_LAW,
  TRANS_TIME,
  TRANS_ELEC,
  TRANS_RATE,
  TRANS_AJITAN,
  DEPARTMENT_MAIN,
  DEPARTMENT_RELATED,
  DEPARTMENT_DETAIL,
  SEARCH,
} from "./settings/ConstantRoutes";

const HomePage = React.lazy(() => {
  return import("./pages/home/Home.js");
});
const SearchPage = React.lazy(() => {
  return import("./pages/search/Search.js");
});

const AboutUsPage = React.lazy(() => {
  return import("./pages/aboutUs/AboutUs.js");
});
const SymbolPage = React.lazy(() => {
  return import("./pages/aboutUs/Symbol.js");
});
const AnthemPage = React.lazy(() => {
  return import("./pages/aboutUs/Anthem.js");
});
const GreetingsPage = React.lazy(() => {
  return import("./pages/aboutUs/Greetings.js");
});
const ProgramPage = React.lazy(() => {
  return import("./pages/aboutUs/Program.js");
});
const StrategyPage = React.lazy(() => {
  return import("./pages/aboutUs/Strategy.js");
});
const AboutUsDepartmentPage = React.lazy(() => {
  return import("./pages/aboutUs/AboutUsDepartment.js");
});
const VisionPage = React.lazy(() => {
  return import("./pages/aboutUs/Vision.js");
});
const ExecutivesPage = React.lazy(() => {
  return import("./pages/aboutUs/Executives.js");
});

const NewsFeedPage = React.lazy(() => {
  return import("./pages/newsfeed/news/NewsFeed.js");
});
const VideoNewsFeedPage = React.lazy(() => {
  return import("./pages/newsfeed/news/VideoNewsFeed.js");
});
const PhotoNewsFeedPage = React.lazy(() => {
  return import("./pages/newsfeed/news/PhotoNewsFeed.js");
});
const NewsFeedDetailPage = React.lazy(() => {
  return import("./pages/newsfeed/news/NewsFeedDetail.js");
});
const VideoNewsDetailPage = React.lazy(() => {
  return import("./components/news/VideoNewsDetail.js");
});
const PhotoNewsDetailPage = React.lazy(() => {
  return import("./components/news/PhotoNewsDetail.js");
});

const QuickAdvicePage = React.lazy(() => {
  return import("./pages/newsfeed/quickAdvice/QuickAdvice.js");
});
const QuickAdviceDetailPage = React.lazy(() => {
  return import("./pages/newsfeed/quickAdvice/QuickAdviceDetail.js");
});
const ProcurementListPage = React.lazy(() => {
  return import("./pages/newsfeed/procurement/Procurements.js");
});
const OrderListPage = React.lazy(() => {
  return import("./pages/newsfeed/order/OrderList.js");
});

const CommitteeListPage = React.lazy(() => {
  return import("./pages/committee/CommitteeList.js");
});
const CommitteeDetailPage = React.lazy(() => {
  return import("./pages/committee/CommitteeDetail.js");
});

const ServiceListPage = React.lazy(() => {
  return import("./pages/stateService/StateService.js");
});
const ServiceDetailPage = React.lazy(() => {
  return import("./pages/stateService/StateServiceDetail.js");
});

const TransFinancesPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyFinances.js");
});
const TransActivityPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyActivity.js");
});
const TransHRPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyHR.js");
});
const TransProcurementPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyProcurement.js");
});
const TransSanalPage = React.lazy(() => {
  return import("./pages/transparency/TransparencySanal.js");
});
const TransArchivePage = React.lazy(() => {
  return import("./pages/transparency/TransparencyArchive.js");
});
const TransLawPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyLaw.js");
});
const TransAppointmentPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyAppointment.js");
});
const TransElectionPage = React.lazy(() => {
  return import("./pages/transparency/TransparencyElection.js");
});
const TransRatePage = React.lazy(() => {
  return import("./pages/transparency/TransparencyRate.js");
});
const TransAjiltanPage = React.lazy(() => {
  return import("./pages/shilenDans/ShilenDansAjiltanInfo.js");
});

const MainDepartmentsPage = React.lazy(() => {
  return import("./pages/departmens/MainDepartments.js");
});
const RelatedDepartmentsPage = React.lazy(() => {
  return import("./pages/departmens/RelatedDepartments.js");
});
const MainDepartmentDetailPage = React.lazy(() => {
  return import("./pages/departmens/DepartmentDetail.js");
});

const routes = [
  {
    path: HOME,
    component: <HomePage />,
    exact: true,
  },
  {
    path: SEARCH,
    component: <SearchPage />,
    exact: false,
  },
  {
    path: ABOUT_US,
    component: <AboutUsPage />,
    exact: false,
  },
  {
    path: SYMBOL,
    component: <SymbolPage />,
    exact: false,
  },
  {
    path: ANTHEM,
    component: <AnthemPage />,
    exact: false,
  },
  {
    path: GREETINGS,
    component: <GreetingsPage />,
    exact: false,
  },
  {
    path: PROGRAM,
    component: <ProgramPage />,
    exact: false,
  },
  {
    path: STRATEGY,
    component: <StrategyPage />,
    exact: false,
  },
  {
    path: ABOUT_US_DEPARTMENT,
    component: <AboutUsDepartmentPage />,
    exact: false,
  },
  {
    path: VISION,
    component: <VisionPage />,
    exact: false,
  },
  {
    path: EXECUTIVES,
    component: <ExecutivesPage />,
    exact: false,
  },
  {
    path: NEWSFEED,
    component: <NewsFeedPage />,
    exact: false,
  },
  {
    path: NEWSFEED_DETAIL,
    component: <NewsFeedDetailPage />,
    exact: false,
  },
  {
    path: VIDEO_NEWS_DETAIL,
    component: <VideoNewsDetailPage />,
    exact: false,
  },
  {
    path: PHOTO_NEWS_DETAIL,
    component: <PhotoNewsDetailPage />,
    exact: false,
  },
  {
    path: QUICK_ADVICE,
    component: <QuickAdvicePage />,
    exact: false,
  },
  {
    path: QUICK_ADVICE_DETAIL,
    component: <QuickAdviceDetailPage />,
    exact: false,
  },
  {
    path: PROCUREMENT_LIST,
    component: <ProcurementListPage />,
    exact: false,
  },
  {
    path: ORDER_LIST,
    component: <OrderListPage />,
    exact: false,
  },
  {
    path: COMMITTEE_LIST,
    component: <CommitteeListPage />,
    exact: false,
  },
  {
    path: COMMITTEE_DETAIL,
    component: <CommitteeDetailPage />,
    exact: false,
  },
  {
    path: SERVICE_LIST,
    component: <ServiceListPage />,
    exact: false,
  },
  {
    path: SERVICE_DETAIL,
    component: <ServiceDetailPage />,
    exact: false,
  },
  {
    path: TRANS_FIN,
    component: <TransFinancesPage />,
    exact: false,
  },
  {
    path: TRANS_WORK,
    component: <TransActivityPage />,
    exact: false,
  },
  {
    path: TRANS_HR,
    component: <TransHRPage />,
    exact: false,
  },
  {
    path: TRANS_PROC,
    component: <TransProcurementPage />,
    exact: false,
  },
  {
    path: TRANS_COMP,
    component: <TransSanalPage />,
    exact: false,
  },
  {
    path: TRANS_ARCH,
    component: <TransArchivePage />,
    exact: false,
  },
  {
    path: TRANS_LAW,
    component: <TransLawPage />,
    exact: false,
  },
  {
    path: TRANS_TIME,
    component: <TransAppointmentPage />,
    exact: false,
  },
  {
    path: TRANS_ELEC,
    component: <TransElectionPage />,
    exact: false,
  },
  {
    path: TRANS_RATE,
    component: <TransRatePage />,
    exact: false,
  },
  {
    path: TRANS_AJITAN,
    component: <TransAjiltanPage />,
    exact: false,
  },
  {
    path: DEPARTMENT_MAIN,
    component: <MainDepartmentsPage />,
    exact: false,
  },
  {
    path: DEPARTMENT_RELATED,
    component: <RelatedDepartmentsPage />,
    exact: false,
  },
  {
    path: DEPARTMENT_DETAIL,
    component: <MainDepartmentDetailPage />,
    exact: false,
  },
  {
    path: VIDEO_NEWS,
    component: <VideoNewsFeedPage />,
    exact: false,
  },
  {
    path: PHOTO_NEWS,
    component: <PhotoNewsFeedPage />,
    exact: false,
  },
];

export default function AllRoutes() {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="flex justify-center items-center mt-[250px]">
            {"Түр хүлээнэ үү..."}
          </div>
        }
      >
        <Routes>
          {routes.map(({ path, component, exact = true }) => (
            <Route key={path} path={path} exact={exact} element={component} />
          ))}
        </Routes>
      </Suspense>
    </Layout>
  );
}
