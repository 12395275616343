import React, { useEffect, useState } from "react";

export default function GoUpButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisiblity);

    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisiblity);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {showButton && (
        <div className="">
          <button
            className="fixed bottom-5 right-7 z-50 cursor-pointer p-4 rounded-full h-10 w-10 flex items-center justify-center border bg-white hover:bg-bgd-main_blue_color hover:text-white hover:border-none"
            onClick={handleScrollToTop}
          >
            <i className="fa-solid fa-arrow-up"></i>
          </button>
        </div>
      )}
    </>
  );
}
