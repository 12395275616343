import React from "react";

import { socialButtons } from "../../mock-data/Data";

export default function SocialButtons() {
  return (
    <div className="hidden lg:block fixed top-[50%] left-1 z-20 space-y-1">
      {socialButtons.map((row, index) => (
        <div
          key={index}
          className={`h-10 w-10 rounded-[5px] cursor-pointer ${
            row.code === "weather" && `bg-bgd-main_blue_color`
          }`}
        >
          <a
            className="text-white"
            href={row.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-full h-full rounded-[5px]"
              src={row.image}
              alt="social"
            />
          </a>
        </div>
      ))}
    </div>
  );
}
