import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AllRoutes from "./route";

function App() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   window.location.replace(
  //     process.env.REACT_APP_SOCIAL_SHARE_URL + window.location.pathname + "/1"
  //   );
  // }, []);

  // return <div>Redirecting...</div>;
  return <AllRoutes />;
}

export default App;
